<template>
  <v-app>
    <template v-if="this.$route.name !== 'login'">
      <v-navigation-drawer
        v-model="drawer"
        app
        clipped
      >
        <v-list
          dense
          nav
        >
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-view-dashboard</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/klanten">
            <v-list-item-icon>
              <v-icon>mdi-account-check</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Klanten</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item to="/uren">
            <v-list-item-icon>
              <v-icon>mdi-timetable</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>Uren</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <v-app-bar
        v-if="!this.$vuetify.breakpoint.smAndDown"
        app
        color="blue-grey darken-2"
        dark
        clipped-left
      >
        <div class="d-flex align-center">
          <h2 class="ml-4">DigiWorx ERP</h2>
        </div>
        <v-spacer></v-spacer>
        <v-icon
          @click.stop="logout()"
        >
          mdi-logout
        </v-icon>
      </v-app-bar>
    </template>
    <v-main>
      <router-view @toggleDrawer="drawer = !drawer" />
    </v-main>
  </v-app>
</template>

<script>
import axios from 'axios';

export default {
  name: 'App',

  data: () => ({
    drawer: true
  }),
  methods: {
    async logout () {
        this.overlay = true
        await axios.post('https://erp.digiworx.nl/api/auth/logout', { token: localStorage.token })

        .then((response) => {
          // handle success
          this.$router.push({ path: '/login' })
          this.overlay = false
        })
        .catch((error) => {
          // handle error
          console.log(error);
          this.errorMessage = error
          this.errored = true
          this.overlay = false
        })
      },
  }
};
</script>
