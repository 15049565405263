import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import axios from 'axios'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: guardMyroute,
    component: HomeView
  },
  {
    path: '/klanten',
    name: 'klanten',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/KlantenView.vue')
  },
  {
    path: '/uren',
    name: 'uren',
    beforeEnter: guardMyroute,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/UrenView.vue')
  },
  {
    path: '/login',
    name: 'login',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/LoginView.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function guardMyroute(to, from, next)
{
  axios
    .post('https://erp.digiworx.nl/api/auth', { token: localStorage.token })
    .then(response => {
      if(response.data.authenticated === '1') {
        next();
      }
      else {
        next('/login');
      }
    })
    .catch(error => {
      console.log(error)
    })
}

export default router
