<template>
  <v-container fluid>
    <template v-if="this.$vuetify.breakpoint.smAndDown">
      <v-app-bar
        app
        color="blue-grey darken-2"
        dark
        clipped-left
      >
        <v-app-bar-nav-icon @click.stop="toggleDrawer()"></v-app-bar-nav-icon>
        <v-toolbar-title>Home</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon>
          <v-icon>mdi-dots-vertical</v-icon>
        </v-btn>
      </v-app-bar>
    </template>
  </v-container>
</template>

<script>

  export default {
    name: 'HomeView',
    methods: {
      toggleDrawer () {
        this.$emit('toggleDrawer', '')
      },
    }
  }
</script>
